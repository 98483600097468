:root {
  --toastify-toast-width: 400px;
  --toastify-toast-background: transparent;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast {
  padding: 0;
  margin: 0;
  margin-top: 60px;
  box-shadow: none;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast-theme--light {
  background: transparent;
  color: transparent;
}
