/* loader */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.large-lds-ring {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}

.large-lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 56px;
  height: 56px;
  margin: 2px auto;
  border: 2px solid #482474;
  border-radius: 50%;
  animation: large-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #482474 transparent transparent transparent;
}

.large-lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.large-lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.large-lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes large-lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ring.is-large {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}

.lds-ring.is-large div {
  width: 56px;
  height: 56px;
  margin: 2px auto;
  border: 2px solid #482474;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #482474 transparent transparent transparent;
}

circle {
  stroke-opacity: 1;
}
